@font-face {
  font-family: 'gif3';
  src:  url('../fonts/gif3.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="gif3-"], [class*=" gif3-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'gif3' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gif3{
  font-size: 25px;
}

.gif3-box-add:before {
  content: "\e97d";
}
.gif3-box-add-bold:before {
  content: "\e974";
}
.gif3-message:before {
  content: "\e975";
}
.gif3-message-bold:before {
  content: "\e976";
}
.gif3-quote:before {
  content: "\e977";
}
.gif3-quote-bold:before {
  content: "\e978";
}
.gif3-talk:before {
  content: "\e979";
}
.gif3-talk-bold:before {
  content: "\e97a";
}
.gif3-terminal:before {
  content: "\e97b";
}
.gif3-terminal-bold:before {
  content: "\e97c";
}
.gif3-copy-bold:before {
  content: "\e900";
}
.gif3-copy:before {
  content: "\e901";
}
.gif3-alert:before {
  content: "\e902";
}
.gif3-alert-bold:before {
  content: "\e903";
}
.gif3-trash:before {
  content: "\e904";
}
.gif3-trash-bold:before {
  content: "\e905";
}
.gif3-compass:before {
  content: "\e906";
}
.gif3-compass-bold:before {
  content: "\e907";
}
.gif3-download:before {
  content: "\e908";
}
.gif3-download-bold:before {
  content: "\e909";
}
.gif3-flash-bold:before {
  content: "\e90a";
}
.gif3-flash-remove-bold:before {
  content: "\e90b";
}
.gif3-flash-remove:before {
  content: "\e90c";
}
.gif3-flash:before {
  content: "\e90d";
}
.gif3-gif:before {
  content: "\e90e";
}
.gif3-gif-bold:before {
  content: "\e90f";
}

.gif3-box:before {
  content: "\e915";
}
.gif3-box-bold:before {
  content: "\e914";
}
.gif3-hashtag-bold:before {
  content: "\e916";
}
.gif3-hashtag:before {
  content: "\e917";
}
.gif3-like:before {
  content: "\e918";
}
.gif3-like-bold:before {
  content: "\e919";
}
.gif3-image:before {
  content: "\e91a";
}
.gif3-image-bold:before {
  content: "\e91b";
}
.gif3-more-horizontal:before {
  content: "\e91c";
}
.gif3-more-vertical-bold:before {
  content: "\e91d";
}
.gif3-more-horizontal-bold:before {
  content: "\e91e";
}
.gif3-more-vertical:before {
  content: "\e91f";
}
.gif3-info:before {
  content: "\e920";
}
.gif3-info-bold:before {
  content: "\e921";
}
.gif3-lock:before {
  content: "\e922";
}
.gif3-lock-bold:before {
  content: "\e923";
}
.gif3-login:before {
  content: "\e924";
}
.gif3-login-bold:before {
  content: "\e925";
}
.gif3-chat-bold:before {
  content: "\e926";
}
.gif3-add-chat-bold:before {
  content: "\e927";
}
.gif3-add-chat:before {
  content: "\e928";
}
.gif3-chat:before {
  content: "\e929";
}
.gif3-minus:before {
  content: "\e92a";
}
.gif3-minus-bold:before {
  content: "\e92b";
}
.gif3-bell:before {
  content: "\e92c";
}
.gif3-bell-bold:before {
  content: "\e92d";
}
.gif3-play-bold:before {
  content: "\e92e";
}
.gif3-play:before {
  content: "\e92f";
}
.gif3-add:before {
  content: "\e930";
}
.gif3-add-bold:before {
  content: "\e931";
}
.gif3-profile:before {
  content: "\e932";
}
.gif3-profile-bold:before {
  content: "\e933";
}
.gif3-qr-code-bold:before {
  content: "\e934";
}
.gif3-qr-code:before {
  content: "\e935";
}
.gif3-emoji-bold:before {
  content: "\e936";
}
.gif3-emoji:before {
  content: "\e937";
}
.gif3-scan:before {
  content: "\e938";
}
.gif3-scan-bold:before {
  content: "\e939";
}
.gif3-search:before {
  content: "\e93a";
}
.gif3-search-bold:before {
  content: "\e93b";
}
.gif3-send:before {
  content: "\e93c";
}
.gif3-send-bold:before {
  content: "\e93d";
}
.gif3-setting:before {
  content: "\e93e";
}
.gif3-setting-bold:before {
  content: "\e93f";
}
.gif3-shield:before {
  content: "\e940";
}
.gif3-shield-bold:before {
  content: "\e941";
}
.gif3-eye:before {
  content: "\e942";
}
.gif3-eye-bold:before {
  content: "\e943";
}
.gif3-effect-bold:before {
  content: "\e944";
}
.gif3-effect:before {
  content: "\e945";
}
.gif3-checkbox:before {
  content: "\e946";
}
.gif3-checkbox-bold:before {
  content: "\e947";
}
.gif3-time:before {
  content: "\e948";
}
.gif3-time-bold:before {
  content: "\e949";
}
.gif3-title-bold:before {
  content: "\e94a";
}
.gif3-title:before {
  content: "\e94b";
}
.gif3-unlock:before {
  content: "\e94c";
}
.gif3-unlock-bold:before {
  content: "\e94d";
}
.gif3-micro:before {
  content: "\e94e";
}
.gif3-micro-bold:before {
  content: "\e94f";
}
.gif3-work:before {
  content: "\e950";
}
.gif3-work-bold:before {
  content: "\e951";
}
.gif3-align-center-bold:before {
  content: "\e952";
}
.gif3-align-center:before {
  content: "\e953";
}
.gif3-align-left-bold:before {
  content: "\e954";
}
.gif3-text-bold:before {
  content: "\e955";
}
.gif3-text:before {
  content: "\e956";
}
.gif3-align-left:before {
  content: "\e957";
}
.gif3-align-right-bold:before {
  content: "\e958";
}
.gif3-align-right:before {
  content: "\e959";
}
.gif3-arrow-right-bold:before {
  content: "\e95a";
}
.gif3-arrow-right:before {
  content: "\e95b";
}
.gif3-bookmark:before {
  content: "\e95c";
}
.gif3-bookmark-bold:before {
  content: "\e95d";
}
.gif3-bot-bold:before {
  content: "\e95e";
}
.gif3-bot:before {
  content: "\e95f";
}
.gif3-buy:before {
  content: "\e960";
}
.gif3-buy-bold:before {
  content: "\e961";
}
.gif3-camera:before {
  content: "\e962";
}
.gif3-camera-bold:before {
  content: "\e963";
}
.gif3-comment-bold:before {
  content: "\e964";
}
.gif3-comment:before {
  content: "\e965";
}
.gif3-check:before {
  content: "\e966";
}
.gif3-check-bold:before {
  content: "\e967";
}
.gif3-clock-dash-bold:before {
  content: "\e968";
}
.gif3-clock-dash:before {
  content: "\e969";
}
.gif3-close-bold:before {
  content: "\e96a";
}
.gif3-close:before {
  content: "\e96b";
}
.gif3-remove:before {
  content: "\e96c";
}
.gif3-remove-bold:before {
  content: "\e96d";
}
.gif3-swap-bold:before {
  content: "\e96e";
}
.gif3-swap:before {
  content: "\e96f";
}
.gif3-color-bold:before {
  content: "\e970";
}
.gif3-color:before {
  content: "\e971";
}
.gif3-arrow-left-bold:before {
  content: "\e973";
}
.gif3-eye-hide:before {
  content: "\e910";
}
.gif3-eye-hide-bold:before {
  content: "\e911";
}
.gif3-signout:before {
  content: "\e912";
}
.gif3-signout-bold:before {
  content: "\e913";
}
.gif3-arrow-left:before {
  content: "\e972";
}
